import type { AppModule } from '@/types'
import MapLibrePlugin from '@/components/MapLibre/maplibrePlugin'

export default {
  install: (app) => {
    app.use(MapLibrePlugin, {
      style: 'mapbox://styles/tdelmas/cm3mwqog400mq01s93lfs02ol',
      i18n: true,
    })
  },
} as AppModule
